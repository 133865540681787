<template>
  <div class="page-wrapper white">
    <div class="page-info">
      <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="140rem" label-position="left">
        <el-form-item label="咨询主题" prop="theme_id">
          <el-select v-model="formData.theme_id" placeholder="请选咨询主题">
            <el-option v-for="item in themeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生姓名" prop="student_id">
          <el-select v-model="formData.student_id" filterable remote reserve-keyword placeholder="请输入学生关键字" @change="handleIdChange" :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in studentsList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="咨询时间" prop="start_at">
          <el-button type="primary" plain size="small" @click="handleShowDialog">
            {{ formData.start_at ? formData.start_at + ' ~ ' + formData.end_at : '选择时间' }}
          </el-button>
        </el-form-item>
        <el-form-item label="咨询地点" prop="chamber_id">
          <el-select v-model="formData.chamber_id" placeholder="请选择咨询地点">
            <el-option v-for="item in roomList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否发送问卷" prop="send">
          <el-switch v-model="formData.send"></el-switch>
        </el-form-item>
        <el-form-item label="问卷" prop="paper_id" v-if="formData.send">
          <el-select v-model="formData.paper_id" placeholder="请选择问卷">
            <el-option v-for="item in paperList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="留给学生的话" prop="teacher_remark">
          <el-input type="textarea" v-model="formData.teacher_remark" maxlength="100" show-word-limit :autosize="autosize"></el-input>
        </el-form-item>
        <el-form-item> </el-form-item>
      </el-form>
      <TimeSelect :visible.sync="isShowDialog" :school_id="school_id" @submit="handleDateSubmit"></TimeSelect>
    </div>
    <div class="page-tool border">
      <el-button type="primary" @click="submitForm('ruleForm')">发起预约</el-button>
      <el-button @click="resetForm('ruleForm')">取消</el-button>
    </div>
  </div>
</template>

<script>
import TimeSelect from '../components/TimeSelect.vue'
import { getApi2, postApi2 } from '@/api'
export default {
	_config:{"route":{"path":"initiate","meta":{"title":"发起预约"}}},
  name: 'Initiate',
  components: { TimeSelect },
  data() {
    return {
      autosize: {
        minRows: 3,
        maxRows: 4
      },
      loading: false,
      isShowDialog: false,
      formData: {
        student_id: '',
        theme_id: '',
        chamber_id: '',
        paper_id: '',
        start_at: '',
        end_at: '',
        teacher_remark: '',
        send: true
      },
      rules: {
        theme_id: [{ required: true, message: '请选择咨询主题', trigger: 'change' }],
        student_id: [{ required: true, message: '请选择咨询学生', trigger: 'change' }],
        chamber_id: [{ required: true, message: '请选择咨询地点', trigger: 'change' }],
        start_at: [{ required: true, message: '请选择咨询时间', trigger: 'change' }],
        paper_id: [{ required: true, message: '请选择问卷', trigger: 'change' }],
        teacher_remark: [{ required: true, message: '请输入留给学生的话', trigger: 'change' }]
      },
      studentsList: [],
      roomList: [],
      themeList: [],
      paperList: [],
      school_id: 0
    }
  },
  watch: {
    school_id(val) {
      if (val) {
        this.formData.chamber_id = ''
        this.formData.start_at = ''
        this.formData.end_at = ''
        this.getRoom()
      }
    }
  },
  created() {
    this.getTheme()
    this.getPaper()
  },
  methods: {
    handleIdChange(val) {
      const obj = this.studentsList.find(item => item.value === val)
      this.school_id = obj.school_id
    },
    getTheme() {
      getApi2('/evaluation/appointment/get-param').then(res => {
        const { theme } = res
        this.themeList = theme.map(item => ({ label: item.sub_name, value: item.id }))
      })
    },
    getRoom() {
      getApi2('/evaluation/counseling-setting/chamber-enumeration', { school_id: this.school_id }).then(res => {
        this.roomList = res.map(item => ({ label: `${item.chamber_name}（${item.building_name}${item.chamber_no}）`, value: item.id }))
      })
    },
    getPaper() {
      getApi2('/evaluation/appointment/paper').then(res => {
        this.paperList = res.map(item => ({ label: item.name, value: item.id }))
      })
    },
    onSearch(keyword) {
      getApi2('/evaluation/appointment/student', { keyword })
        .then(res => {
          this.studentsList = res.map(item => ({
            value: item.id,
            label: `${item.student_name}(${item.student_no})`,
            school_id: item.school_id
          }))
        })
        .finally(() => {
          this.loading = false
        })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.onSearch(query)
      } else {
        this.studentsList = []
      }
    },
    handleShowDialog() {
      this.isShowDialog = true
    },
    handleDateSubmit(val) {
      this.formData.start_at = val.startTime
      this.formData.end_at = val.endTime
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let { send, paper_id, ...data } = this.formData
          data.paper_id = send ? paper_id : 0
          postApi2('/evaluation/appointment/make', data).then(res => {
            this.$router.back()
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields()
      let that = this
      this.$confirm('确定取消预约？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$router.back()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
