<template>
  <el-dialog title="可咨询时间段" :close-on-click-modal="true" :visible="visible" @close="handleCloseDialog">
    <template v-if="school_id">
      <div style="margin-bottom: 20rem">
        <span class="mr-2">日期</span>
        <el-date-picker
          style="width: 130rem"
          v-model="date"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          size="small"
          :picker-options="pickerOptions"
          :clearable="false"
          :append-to-body="false"
          @change="handleChange"
        >
        </el-date-picker>
      </div>
      <div>
        <el-table :data="list" style="width: 100%" size="small" v-loading="loading" :stripe="false">
          <el-table-column prop="time" label="时间段" width="140rem">
            <template slot-scope="scope">
              <span>{{ scope.row.start }}</span>
              -
              <span>{{ scope.row.end }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="咨询老师">
            <template slot-scope="scope">{{ name }}</template>
          </el-table-column>
          <el-table-column prop="score" label="操作" width="120rem">
            <template slot-scope="scope">
              <el-button :type="scope.row.past || scope.row.occupy ? 'info' : 'primary'" size="mini" :disabled="scope.row.past || scope.row.occupy" @click="handleOrder(scope.row)">{{
                scope.row.occupy ? '已约' : '预约'
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="apply-row" v-if="list.length">
          <div class="item flex-1">
            <el-time-picker
              style="width: 240rem"
              v-model="timeRange"
              size="small"
              format="HH:mm"
              value-format="HH:mm"
              is-range
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              :append-to-body="false"
              @change="handleChangeTime"
            >
            </el-time-picker>
          </div>
          <div class="item order"><el-button :loading="isChecking" type="primary" size="mini" debounce @click="() => handleOrder()">预约</el-button></div>
        </div>
        <div v-else-if="!list.length && !loading" class="no-data">
          <div class="pt-4 pb-4">未配置可咨询的时间，请前往配置中心配置！</div>
          <el-button type="primary" size="small" @click="handlePath">马上配置</el-button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="no-data pt-4">请先选择学生</div>
    </template>
  </el-dialog>
</template>

<script>
import { formatDate } from '@/utils'
import { getApi2, postApi2 } from '@/api'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    school_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      isChecking: false,
      isCheckPass: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 1000 * 60 * 60 * 24
        }
      },
      date: formatDate().date,
      startTime: '',
      endTime: '',
      list: [],
      name: '',
      timeRange: null
    }
  },
  watch: {
    school_id(val) {
      if (val) {
        this.getList()
      }
    }
  },
  created() {
    const { userinfo } = this.$store.getters.userInfo
    if (userinfo) {
      this.name = userinfo.name
    }
  },
  methods: {
    checkTime(start_at, end_at, date) {
      this.isChecking = true
      this.isCheckPass = false
      postApi2('/evaluation/counseling-action/apply/custom-time', { start_at, end_at, date })
        .then(() => {
          this.isCheckPass = true
          this.startTime = start_at
          this.endTime = end_at
        })
        .finally(() => {
          this.isChecking = false
        })
    },
    getList() {
      this.loading = true
      this.list = []
      getApi2('/evaluation/appointment/occupy', { date: this.date, school_id: this.school_id })
        .then(res => {
          this.list = res
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleChangeTime(e) {
      if (e) {
        this.checkTime(e[0], e[1], this.date)
      } else {
        this.startTime = ''
        this.endTime = ''
      }
    },
    handleChange(e) {
      this.date = e
      this.getList()
    },
    handleCloseDialog() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    handleOrder(row) {
      if (row) {
        this.$emit('submit', { date: this.date, start_at: row.start, end_at: row.end, startTime: `${this.date} ${row.start}`, endTime: `${this.date} ${row.end}` })
      } else {
        if (this.isChecking) {
          return this.$message.warning('检测中，请稍等！')
        }
        if (!this.isCheckPass) {
          return this.$message.warning('请重新选择时间')
        }
        if (!this.startTime) {
          return this.$message.warning('请选择开始时间')
        }
        if (!this.endTime) {
          return this.$message.warning('请选择结束时间')
        }
        this.$emit('submit', { date: this.date, start_at: this.startTime, end_at: this.endTime, startTime: `${this.date} ${this.startTime}`, endTime: `${this.date} ${this.endTime}` })
      }
      this.handleCloseDialog()
    },
    handlePath() {
      this.$router.push('/moral/psychology-config/date/list')
    },
    getIsDisabled(row) {
      const { date, hour, minute } = formatDate()
      const arr = row.start.split(':').map(Number)
      const orderTime = arr[0] * 60 + arr[1]
      const current = hour * 60 + minute
      return date === this.date ? current > orderTime : false
    }
  }
}
</script>
<style lang="scss" scoped>
.apply-row {
  padding: 20rem 0;
  display: flex;
  .item {
    padding-left: 22rem;
  }
  .order {
    flex-shrink: 0;
    width: 120rem;
  }
}
.no-data {
  text-align: center;
}
::v-deep .el-range-input {
  width: 70rem !important;
}
::v-deep .el-range-separator {
  width: 30rem !important;
}
</style>
